<template>
  <base-section id="k-d-a-solutions-short-desciption" class="white" space="0">
    <v-container :class="`my-${g_bLowerBr ? 5 : 10} px-7`">
      <v-row>
        <v-col>
          <h1 :class="`kda-ts-${g_bLowerBr ? 24 : 80}pt wt-extrabold`">{{ m_strTitle }}</h1>
        </v-col>
      </v-row>
      <v-row :no-gutters="g_bLowerBr">
        <v-col v-if="$vuetify.breakpoint.lgAndUp" lg="1" md="1" />
        <v-col cols="12" lg="11" md="11">
          <h5
            :class="`kda-ts-${g_bLowerBr ? 12 : 24}pt nunito wt-regular center`"
            :style="`${g_bLowerBr ? 'padding-top: 10px' : ''} `"
          >
            {{ m_strDetail }}
          </h5>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDASolutionsShortDescription',

  mixins: [BaseBreakpoint],

  computed: {
    m_strTitle() {
      return this.$vuetify.lang.t('$vuetify.kda.solution.header.title');
    },
    m_strDetail() {
      return this.$vuetify.lang.t('$vuetify.kda.solution.header.desc');
    }
  }
};
</script>
